import Const from '../constants'
import axios from 'axios'

class StoreTourModeService {
  getAllStoreTourMode() {
    return axios.get(Const.api_url + 'all-store')
  }
}

export default new StoreTourModeService()
